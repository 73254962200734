import type { FC } from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { useRouter } from "../hooks/use-router";

interface SeoProps {
   title: string;
   description: string;
   appendBrandToTile?: boolean;
   coverImage?: string;
}

export const Seo: FC<SeoProps> = (props) => {
   const {
      title,
      description,
      appendBrandToTile = true,
      coverImage = "",
   } = props;
   const { asPath } = useRouter();

   const fullTitle = appendBrandToTile ? title + " | Union Near Me" : title;
   const baseUrl = process.env.NEXT_PUBLIC_CANONICAL_BASE;
   const currentUrl = baseUrl + asPath;

   return (
       <Head>
           <title>{fullTitle}</title>
           <link rel="canonical"
                 href={currentUrl}/>
           <meta name="description"
                 content={description}/>

           <meta property="og:type"
                 content="website"/>
           <meta property="og:url"
                 content={currentUrl}/>
           <meta property="og:title"
                 content={fullTitle}/>
           <meta property="og:description"
                 content={description}/>

           <meta property="twitter:card"
                 content="website"/>
           <meta property="twitter:url"
                 content={currentUrl}/>
           <meta property="twitter:title"
                 content={fullTitle}/>
           <meta property="twitter:description"
                 content={description}/>
           <meta property="twitter:site"
                 content="@unionnearme"/>
           <meta property="twitter:creator"
                 content="@unionnearme"/>

           {coverImage != "" && (
               <meta property="twitter:image"
                     content={baseUrl + coverImage}/>
           )}

           <meta name="viewport"
content="width=device-width, initial-scale=1, maximum-scale=1"/>
       </Head>
   );
};

Seo.propTypes = {
    title: PropTypes.string,
};
