import * as React from "react";
import {
   TextField,
   Autocomplete,
   CircularProgress,
   InputAdornment,
   ListItem,
   Typography,
   Grid,
   AutocompletePropsSizeOverrides,
   Box,
   Link,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useMemo, FunctionComponent } from "react";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import { useMounted } from "../hooks/use-mounted";
import { Feature, Pelias } from "../slices/frontend-models";
import { OverridableStringUnion } from "@mui/types";
import { useUser } from "../hooks/use-user";
import { useGetLocationURL } from "../hooks/use-api";
import { paths } from "../paths";

interface Props {
   size?: OverridableStringUnion<
      "small" | "medium",
      AutocompletePropsSizeOverrides
   >;
   placeholder?: string;
   setSelectedLocation: any;
   error?: boolean;
   helperText?: string;
   dontClearSearchOnSelect?: boolean;
   selectedLocation?: {
      locationKey: number;
      feature?: Feature;
      searchInputText?: string;
   };
}

const AddressSearch: FunctionComponent<Props> = ({
   size,
   placeholder,
   setSelectedLocation,
   error,
   helperText,
   dontClearSearchOnSelect,
   selectedLocation,
}) => {
   const [open, setOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [clearSearch, setClearSearch] = useState(false);
   const [options, setOptions] = useState<Feature[]>([]);
   const [searchValue, setSearchValue] = useState<Feature>(
      selectedLocation?.feature || null,
   );
   const [searchInputText, setSearchInputText] = useState(
      selectedLocation?.feature ? selectedLocation?.searchInputText : "",
   );
   const [searchSelected, setSearchSelected] = useState(false);
   const isMounted = useMounted();
   const { geoLocation, setGeoLocation } = useUser();

   const { data: locationResult } = useGetLocationURL({
      houseNumber: searchValue?.properties.housenumber,
      street: searchValue?.properties.street,
      city: searchValue?.properties.locality,
      region: searchValue?.properties.region_a,
      postCode: searchValue?.properties?.postalcode
         ? searchValue.properties.postalcode.substring(0, 5)
         : " ",
      country: searchValue?.properties.country_code,
      latitude: searchValue?.geometry.coordinates[1],
      longitude: searchValue?.geometry.coordinates[0],
   });

   useEffect(() => {
      if (searchValue) {
         if (locationResult) {
            if (!dontClearSearchOnSelect) {
               setSearchInputText("");
               setClearSearch(!clearSearch);
            }
            setSelectedLocation({
               locationKey: locationResult.locationKey,
               feature: searchValue,
               searchInputText: searchInputText,
            });
         }
      }
   }, [locationResult, searchSelected]);

   const throttledChangeHandler = useMemo(
      () =>
         debounce(async (value, geoLocation) => {
            var locationBias = "";
            if (
               geoLocation &&
               geoLocation.latitude != 0 &&
               geoLocation.longitude != 0
            ) {
               locationBias = `&focus.point.lat=${geoLocation.latitude}&focus.point.lon=${geoLocation.longitude}`;
            }
            fetch(
               `https://geocoder.unionnearme.com/v1/autocomplete?text=${value}&size=5&layers=address${locationBias}`,
            )
               .then((response) => {
                  if (response.ok) {
                     return response.json();
                  }
                  throw new Error("Address lookup failed.");
               })
               .then((pelias: Pelias) => {
                  const uniqueAddresses = uniqBy(
                     pelias.features,
                     function (feature: Feature) {
                        return [
                           feature.properties.housenumber,
                           feature.properties.street,
                           feature.properties.locality,
                           feature.properties.region_a,
                           feature.properties?.postalcode
                              ? feature.properties.postalcode.substring(0, 5)
                              : " ",
                           feature.properties.country_code,
                        ].join();
                     },
                  );
                  uniqueAddresses.push({
                     properties: { id: "" },
                  });
                  setOptions(uniqueAddresses);
                  setIsLoading(false);
               })
               .catch((error) => {
                  setIsLoading(false);
               });
         }, 250),
      [],
   );

   const onSearchChangeHandle = async (event: any, value: any) => {
      if (value !== "" && value !== null) {
         setSearchValue(value);
         setSearchSelected(!searchSelected);
         setSearchInputText(formatAddressName(value));
      }
   };

   const onChangeHandle = async (event: any) => {
      const value = event.target.value;
      setSearchInputText(value);
      if (value !== "" && value !== null) {
         setIsLoading(true);
         await throttledChangeHandler(value, geoLocation);
      } else {
         setSelectedLocation(null);
      }
   };

   useEffect(() => {
      if (!open) {
         setOptions([]);
      }
   }, [open]);

   return (
      <Autocomplete
         key={clearSearch + ""}
         data-cy="address-search"
         size={size}
         freeSolo
         disableClearable
         open={open}
         onChange={onSearchChangeHandle}
         inputValue={searchInputText}
         onOpen={() => {
            setOpen(true);
         }}
         onClose={() => {
            setOpen(false);
         }}
         filterOptions={(x) => x}
         isOptionEqualToValue={(option: Feature, value: Feature) =>
            option.properties.id === value.properties.id
         }
         // @ts-ignore
         getOptionLabel={(option: Feature) => {
            return option.properties.id != "" ? formatAddressName(option) : "";
         }}
         options={options}
         loading={isLoading && open}
         renderOption={(props, option: Feature, state) => {
            if (option.properties.id == "") {
               return (
                  <Grid key={"blank"}
container>
                     <Grid
                        item
                        xs={12}
                        sx={{
                           alignItems: "center",
                           display: "flex",
                           pr: 2,
                           pt: 1,
                        }}
                     >
                        <Box
                           sx={{
                              alignItems: "center",
                              display: "flex",
                              ml: "auto",
                           }}
                        >
                           <Typography
                              color="textSecondary"
                              noWrap
                              variant="caption"
                           >
                              ©{" "}
                              {
                                 <Link
                                    target="_blank"
                                    rel="noopener"
                                    href={paths.openStreetMapsCopyright}
                                 >
                                    Open Street Map
                                 </Link>
                              }{" "}
                              contributors
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               );
            }
            return (
               <ListItem
                  {...props}
                  key={option.properties.id}
                  data-cy={"address-search-option"}
               >
                  <Grid container>
                     <Grid item
xs={12}>
                        {option.properties.housenumber ||
                        option.properties.street
                           ? highlightedTextBlocks(
                                state.inputValue,
                                (option.properties.housenumber || "") +
                                   " " +
                                   (option.properties.street || ""),
                             ).map((item, i) => {
                                if (item.bold) {
                                   return (
                                      <Typography
                                         display="inline"
                                         sx={{ fontWeight: "bold" }}
                                         key={
                                            String(option.properties.id) +
                                            String(i)
                                         }
                                      >
                                         {item.text}
                                      </Typography>
                                   );
                                } else {
                                   return (
                                      <Typography
                                         display="inline"
                                         key={
                                            String(option.properties.id) +
                                            String(i)
                                         }
                                      >
                                         {item.text}
                                      </Typography>
                                   );
                                }
                             })
                           : ""}
                        <Typography
                           display="inline"
                           color="textSecondary"
                           variant="caption"
                           sx={{ pt: "3px" }}
                        >
                           &nbsp;
                           {option.properties.locality
                              ? option.properties.locality + ","
                              : ""}{" "}
                           {option.properties.region_a
                              ? option.properties.region_a
                              : ""}{" "}
                           {option.properties.postalcode
                              ? option.properties.postalcode
                              : ""}
                        </Typography>
                     </Grid>
                  </Grid>
               </ListItem>
            );
         }}
         renderInput={(params) => (
            <TextField
               variant={"outlined"}
               error={error}
               ref={params.InputProps.ref}
               label={searchInputText == "" ? placeholder : ""}
               {...params}
               InputLabelProps={{
                  shrink: false,
                  sx: { pl: "29px", fontSize: 16 },
               }}
               onChange={onChangeHandle}
               fullWidth
               InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                     <InputAdornment position="start">
                        <SearchIcon
                           color={error ? "error" : "primary"}
                           fontSize="small"
                        />
                     </InputAdornment>
                  ),
                  endAdornment: (
                     <>
                        {isLoading && open ? (
                           <CircularProgress color="primary"
size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                     </>
                  ),
               }}
            />
         )}
      />
   );
};

export default AddressSearch;

function highlightedTextBlocks(searchValue: any, text: any) {
   const searchValues = searchValue.split(" ");
   var regexString = "";
   for (var i = 0; i < searchValues.length; i++) {
      if (searchValues[i] != "") {
         if (searchValues[i].length == 1) {
            regexString = regexString + "| " + searchValues[i];
         } else {
            regexString = regexString + "|" + searchValues[i];
         }
      }
   }
   var regex = new RegExp(regexString.substring(1), "ig");
   const matches = [...text.matchAll(regex)];
   var textBlocks = [];
   var index = 0;
   matches.map((match) => {
      if (match.index <= index) {
         textBlocks.push({
            text: text.substr(match.index, match[0].length),
            bold: true,
         });
         index = index + match[0].length;
      } else {
         textBlocks.push({
            text: text.substring(index, match.index),
            bold: false,
         });
         textBlocks.push({
            text: text.substr(match.index, match[0].length),
            bold: true,
         });
         index = match.index + match[0].length;
      }
   });
   if (index < text.length - 1) {
      textBlocks.push({ text: text.substr(index), bold: false });
   }

   return textBlocks;
}

const TO_NAME = 1;
const TO_ABBREVIATED = 2;

function formatAddressName(location: Feature) {
   return (
      (location.properties.housenumber
         ? location.properties.housenumber + " "
         : "") +
      (location.properties.street ? location.properties.street + ", " : "") +
      (location.properties.locality
         ? location.properties.locality + ", "
         : "") +
      (location.properties.region_a ? location.properties.region_a : "") +
      " " +
      (location.properties.postalcode ? location.properties.postalcode : "")
   );
}
