import type { FC } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";

interface Section {
   title: string;
   items: {
      external?: boolean;
      title: string;
      path: string;
   }[];
}

const sections: Section[] = [
   {
      title: "Social",
      items: [
         {
            title: "Facebook",
            external: true,
            path: "https://facebook.com/unionnearme",
         },
         {
            title: "Twitter",
            external: true,
            path: "https://twitter.com/unionnearme",
         },
         {
            title: "Instagram",
            external: true,
            path: "https://www.instagram.com/unionnearme/",
         },
         {
            title: "LinkedIn",
            external: true,
            path: "https://www.linkedin.com/company/unionnearme",
         },
         {
            title: "Reddit",
            external: true,
            path: "https://www.reddit.com/user/unionnearme",
         },
      ],
   },
   {
      title: "Site",
      items: [
         {
            title: "Blog",
            path: "/blog",
         },
         {
            title: "Locations",
            path: "/countries",
         },
         {
            title: "Sitemap",
            path: paths.sitemap,
         },
      ],
   },
   {
      title: "Legal",
      items: [
         {
            title: "Terms & Conditions",
            path: paths.termsAndConditions,
         },
         {
            title: "Privacy Policy",
            path: paths.privacyPolicy,
         },
         {
            title: "Delete My Data",
            path: paths.userDataDeleteRequest,
         },
      ],
   },
];

export const Footer: FC = (props) => (
   <Box
      sx={{
         backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "neutral.800" : "neutral",
         borderTopColor: "divider",
         borderTopStyle: "solid",
         borderTopWidth: 1,
         pb: 6,
         pt: {
            md: 15,
            xs: 6,
         },
      }}
      {...props}
   >
      <Container maxWidth="lg">
         <Grid container
spacing={3}>
            <Grid
               xs={12}
               sm={4}
               md={3}
               sx={{
                  order: {
                     xs: 4,
                     md: 1,
                  },
               }}
            >
               <Stack spacing={1}>
                  <Stack
                     alignItems="center"
                     component={RouterLink}
                     direction="row"
                     display="inline-flex"
                     href={paths.index}
                     spacing={1}
                     sx={{ textDecoration: "none" }}
                  >
                     <Box
                        sx={{
                           display: "inline-flex",
                           height: 24,
                           width: 24,
                        }}
                     >
                        <Logo />
                     </Box>
                     <Box
                        sx={{
                           color: "text.primary",
                           fontFamily: "'Plus Jakarta Sans', sans-serif",
                           fontSize: 14,
                           fontWeight: 800,
                           letterSpacing: "0.3px",
                           lineHeight: 2.5,
                           "& span": {
                              color: "primary.main",
                           },
                        }}
                     >
                        Union Near <span>Me</span>
                     </Box>
                  </Stack>
                  <Typography color="text.secondary"
variant="caption">
                     © {new Date().getFullYear()} UnionNearMe.
                  </Typography>
               </Stack>
            </Grid>
            {sections.map((section, index) => (
               <Grid
                  key={section.title}
                  xs={12}
                  sm={4}
                  md={3}
                  sx={{
                     order: {
                        md: index + 2,
                        xs: index + 1,
                     },
                  }}
               >
                  <Typography color="text.secondary"
variant="overline">
                     {section.title}
                  </Typography>
                  <Stack
                     spacing={1}
                     sx={{
                        m: 0,
                        p: 0,
                     }}
                  >
                     {section.items.map((item) => {
                        const linkProps = item.path
                           ? item.external
                              ? {
                                   component: "a",
                                   href: item.path,
                                   target: "_blank",
                                   rel: "noopener",
                                }
                              : {
                                   component: RouterLink,
                                   href: item.path,
                                }
                           : {};

                        return (
                           <Stack
                              alignItems="center"
                              direction="row"
                              key={item.title}
                              spacing={2}
                           >
                              <Box
                                 sx={{
                                    backgroundColor: "primary.main",
                                    height: 2,
                                    width: 12,
                                 }}
                              />
                              <Link
                                 color="text.primary"
                                 variant="subtitle2"
                                 {...linkProps}
                              >
                                 {item.title}
                              </Link>
                           </Stack>
                        );
                     })}
                  </Stack>
               </Grid>
            ))}
         </Grid>
         <Divider sx={{ my: 6 }} />
         <Typography color="text.secondary"
variant="caption">
            All Rights Reserved.
         </Typography>
      </Container>
   </Box>
);
